import React, { Component } from 'react';
import './styles.css';

// Reach Router
import { Router, Location } from "@reach/router";

// Prime React
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';

// Components
import NavBar from "./NavBar"
import About from "./About"
import Home from "./Home"
import Blog from "./Blog"
import Resume from "./Resume"
import Projects from "./Projects"
import Footer from "./Footer"

// React Pose (animations)
import posed, { PoseGroup } from 'react-pose';

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 400, beforeChildren: 300 },
  exit: { opacity: 0 }
});

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <PoseGroup>
        <RouteContainer key={location.key}>
          <Router location={location} primary={false}>{children}</Router>
        </RouteContainer>
      </PoseGroup>
    )}
  </Location>
);

class App extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <About />
        <PosedRouter>
          <Home path="/"/>
          <Blog path="blog"/>
          <Resume path="resume"/>
          <Projects path="projects"/>
        </PosedRouter>
        <Footer />
      </div>
    );
  }
}

export default App;
