import React from "react";

function Resume() {
  return (
    <div className="block">
      <h1 className="heading">Resume</h1>
      <br />
      <p>
        A full stack web developer motivated by an adoration for Javascript and
        an eagerness to learn. I have a Master’s degree in Mechanical
        Engineering and am proficient in Javascript, React, Redux, Node.js,
        Firebase, Ruby on Rails as well as both SQL and NoSQL databases. I have
        extensive experience working on large-scale projects, with high-pressure
        deadlines, where teamwork was instrumental to their success.
      </p>
      <br />
      <div className="resume-heading"> TECHNICAL PROJECTS </div>
      <h1>
        Parchment -{" "}
        <a
          href="https://github.com/Jordan-Eckowitz/parchment"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Github
        </a>{" "}
        |{" "}
        <a
          href="https://parchment-app.firebaseapp.com/documents"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Demo
        </a>
      </h1>
      <br />
      <p>
        A real-time, collaborative document editor. Documents are composed of
        draggable, interactive flashcards with tabular version control.
      </p>
      <br/>
      <ul>
        <li>Designed the front-end using React and Redux.</li>
        <li>
          Utilized Firebase for hosting, authentication, storage and the
          real-time database (Cloud Firestore).
        </li>
      </ul>
      <br />
      <h1>
        2020 -{" "}
        <a
          href="https://github.com/Jordan-Eckowitz/2020"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Github
        </a>{" "}
        |{" "}
        <a
          href="https://play-2020.firebaseapp.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Demo
        </a>
      </h1>
      <br />
      <p>
        The aim of this strategy game is to clear as many lines as possible. The
        game ends when the user can't fit any new blocks onto the game board.
      </p>
      <br/>
      <ul>
        <li>Built the game using Phaser, a Javascript-based game engine.</li>
        <li>
          Adopted a Ruby on Rails & SQLite back-end to handle users and track
          high scores.
        </li>
      </ul>
      <br />
      <h1>
        Trello 2.0 -{" "}
        <a
          href="https://github.com/Jordan-Eckowitz/Trello-2.0"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Github
        </a>{" "}
        |{" "}
        <a
          href="https://dry-journey-65499.herokuapp.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Demo
        </a>
      </h1>
      <br />
      <p>
        A clone of the popular project management application, Trello. It was
        created using only HTML, CSS, Ruby and Ruby on Rails.
      </p>
      <br />
      <ul>
        <li>Coded the dynamic front-end components in Ruby.</li>
        <li>
          Used Ruby on Rails & SQLite to handle back-end logic, authorization,
          authentication & sessions.
        </li>
      </ul>
      <br />
      <h1>
        Gekko -{" "}
        <a
          href="https://github.com/Jordan-Eckowitz/crypto"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Github
        </a>{" "}
        |{" "}
        <a
          href="https://btc-arb-safrica.herokuapp.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Demo
        </a>
      </h1>
      <br />
      <p>
        An application that tracks arbitrage opportunities across popular
        cryptocurrency exchanges in the USA and South Africa. A messaging bot
        sends users arbitrage updates to their mobile phones.
      </p>
      <br />
      <ul>
        <li>
          Used Meteor Javascript framework for managing the front and back ends
          of the application.
        </li>
        <li>Utilized a MongoDB database, hosted it on mLab.</li>
        <li>
          Created a Telegram bot that messages users when arbitrage exceeds a
          chosen threshold.
        </li>
      </ul>
      <div className="resume-heading"> TECHNICAL SKILLS </div>
      <p>
        HTML, CSS, Javascript, Ruby, Ruby on Rails, React, Redux, Node.js,
        Express, Meteor, Firebase, AWS, VBA, SQL, MongoDB, Heroku
      </p>
      <div className="resume-heading"> EMPLOYMENT HISTORY </div>
      <h1>Arup | Johannesburg, South Africa & New York, NY</h1>
      <h1>Mechanical Engineer, February 2013 - April 2018</h1>
      <br />
      <ul>
        <li>
          Mexico City Airport (2017) - Created the energy model for a new
          airport in Mexico City. Presented innovative modelling technique at
          the IBPSA 2017 conference in San Francisco.
        </li>
        <li>
          First National Bank Data Centers (2014) - Design Engineer on two Tier
          IV rated data centres with a global team from Arup & IBM. The second
          largest data centers in Africa.
        </li>
        <li>
          IBM Research Facility (2015) - Lead Mechanical Engineer for a new tech
          hub and start-up accelerator in Johannesburg. Has a project value of
          $60 Million.
        </li>
      </ul>
      <br />
      <h1>Airbus / National Aerospace Centre | Johannesburg, South Africa</h1>
      <h1>Researcher, January 2011 - December 2012</h1>
      <br />
      <ul>
        <li>
          Offered a research bursary from Airbus UK to investigate methods to
          optimize their Titanium machining operations.
        </li>
      </ul>
      <div className="resume-heading"> EDUCATION </div>
      <h1>Flatiron School | New York, NY, 2018</h1>
      <br />
      <ul>
        <li>
          Immersive Software Engineering Bootcamp - a high intensity, in-person,
          15 week bootcamp on modern web development.
        </li>
      </ul>
      <br />
      <h1>
        University of the Witwatersrand | Johannesburg, South Africa, 2012
      </h1>
      <br />
      <ul>
        <li>Master of Science in Engineering (MSc).</li>
        <li>
          Dissertation Topic: Optimization of the Machining of Ti-6Al-4V under
          MQL and Flood Cooling Conditions.
        </li>
      </ul>
      <br />
      <h1>
        University of the Witwatersrand | Johannesburg, South Africa, 2010
      </h1>
      <br />
      <ul>
        <li>Bachelor of Science in Engineering (BSc).</li>
        <li>
          Awarded a prize for the best final year research project at the
          University of the Witwatersrand, in the field of Mechanical
          Engineering, by the South African Institution of Mechanical
          Engineering (SAIMechE).
        </li>
      </ul>
    </div>
  );
}

export default Resume;
