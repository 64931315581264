import React from "react";

// Icons
import { IconContext } from "react-icons";
import { FaGithubSquare, FaExternalLinkSquareAlt } from "react-icons/fa";

export default function Projects() {
  return (
    <div className="block">
      <h1 className="heading">Projects</h1>
      <br />
      <div className="card">
        <h3>
          Parchment -{" "}
          <a
            href="https://github.com/Jordan-Eckowitz/parchment"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <IconContext.Provider value={{ className: "about-icon" }}>
              Code
              <FaGithubSquare />
            </IconContext.Provider>
          </a>|{" "}
          <a
            href="https://parchment-app.firebaseapp.com/documents"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <IconContext.Provider value={{ className: "about-icon" }}>
              Demo
              <FaExternalLinkSquareAlt />
            </IconContext.Provider>
          </a>
        </h3>
        <br />
        <p>
          A real-time, collaborative document editor. Documents are composed of
          draggable, interactive flashcards with tabular version control.
        </p>
        <br />
        <ul>
          <li>Designed the front-end using React and Redux.</li>
          <li>
            Utilized Firebase for hosting, authentication, storage and the
            real-time database (Cloud Firestore).
          </li>
        </ul>
        <div className="category">Javascript</div>
        <div className="category">React</div>
        <div className="category">Redux</div>
        <div className="category">Firebase</div>
        <div className="category">Firestore</div>
      </div>
      <br />
      <div className="card">
        <h3>
          2020 -{" "}
          <a
            href="https://github.com/Jordan-Eckowitz/2020"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <IconContext.Provider value={{ className: "about-icon" }}>
              Code
              <FaGithubSquare />
            </IconContext.Provider>
          </a>|{" "}
          <a
            href="https://play-2020.firebaseapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <IconContext.Provider value={{ className: "about-icon" }}>
              Demo
              <FaExternalLinkSquareAlt />
            </IconContext.Provider>
          </a>
        </h3>
        <br />
        <p>
          The aim of this strategy game is to clear as many lines as possible.
          The game ends when the user can't fit any new blocks onto the game
          board.
        </p>
        <br />
        <ul>
          <li>Built the game using Phaser, a Javascript-based game engine.</li>
          <li>
            Adopted a Ruby on Rails & SQLite back-end to handle users and track
            high scores.
          </li>
        </ul>
        <div className="category">Javascript</div>
        <div className="category">Phaser</div>
        <div className="category">Ruby</div>
        <div className="category">Ruby On Rails</div>
        <div className="category">SQL</div>
      </div>
      <br />
      <div className="card">
        <h3>
          Trello 2.0 -{" "}
          <a
            href="https://github.com/Jordan-Eckowitz/Trello-2.0"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <IconContext.Provider value={{ className: "about-icon" }}>
              Code
              <FaGithubSquare />
            </IconContext.Provider>
          </a>|{" "}
          <a
            href="https://dry-journey-65499.herokuapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <IconContext.Provider value={{ className: "about-icon" }}>
              Demo
              <FaExternalLinkSquareAlt />
            </IconContext.Provider>
          </a>
        </h3>
        <br />
        <p>
          A clone of the popular project management application, Trello. It was
          created using only HTML, CSS, Ruby and Ruby on Rails.
        </p>
        <br />
        <ul>
          <li>Coded the dynamic front-end components in Ruby.</li>
          <li>
            Used Ruby on Rails & SQLite to handle back-end logic, authorization,
            authentication & sessions.
          </li>
        </ul>
        <div className="category">Ruby</div>
        <div className="category">Ruby On Rails</div>
        <div className="category">SQL</div>
      </div>
      <br />
      <div className="card">
        <h3>
          Gekko -{" "}
          <a
            href="https://github.com/Jordan-Eckowitz/crypto"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <IconContext.Provider value={{ className: "about-icon" }}>
              Code
              <FaGithubSquare />
            </IconContext.Provider>
          </a>|{" "}
          <a
            href="https://btc-arb-safrica.herokuapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <IconContext.Provider value={{ className: "about-icon" }}>
              Demo
              <FaExternalLinkSquareAlt />
            </IconContext.Provider>
          </a>
        </h3>
        <br />
        <p>
          An application that tracks arbitrage opportunities across popular
          cryptocurrency exchanges in the USA and South Africa. A messaging bot
          sends users arbitrage updates to their mobile phones.
        </p>
        <br />
        <ul>
          <li>
            Used Meteor Javascript framework for managing the front and back
            ends of the application.
          </li>
          <li>Utilized a MongoDB database, hosted it on mLab.</li>
          <li>
            Created a Telegram bot that messages users when arbitrage exceeds a
            chosen threshold.
          </li>
        </ul>
        <div className="category">Javascript</div>
        <div className="category">Meteor</div>
        <div className="category">Telegram</div>
        <div className="category">MongoDB</div>
      </div>
    </div>
  );
}
