import firebase from "firebase/app";

// Initialize Firebase with config object from the Firebase project console. 
var config = {
  apiKey: "AIzaSyDaAqvhZ_d_UnaoqIZwFvFHl8Hum-n32Ec",
  authDomain: "my-website-e529f.firebaseapp.com",
  databaseURL: "https://my-website-e529f.firebaseio.com",
  projectId: "my-website-e529f",
  storageBucket: "my-website-e529f.appspot.com",
  messagingSenderId: "881671701510"
};

firebase.initializeApp(config);