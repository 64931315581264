import React from 'react'

function Footer() {
  return (
    <div className="footer">
      <i>Created by Jordan Eckowitz {(new Date()).getFullYear()}</i>
    </div>
  )
}

export default Footer
