import React, { Component } from "react";
import Parser from "rss-parser";

import { PacmanLoader } from "react-spinners";

export default class Blog extends Component {
  state = {
    data: null
  };

  componentDidMount() {
    (async () => {
      const parser = new Parser();
      const blogs = [];
      const feed = await parser.parseURL(
        "https://cors-anywhere.herokuapp.com/https://medium.com/feed/@jordan.eckowitz"
      );

      for (let i = 0; i < feed.items.length; i++) {
        const post = feed.items[i];
        const blog = {
          id: i,
          title: post.title,
          link: post.link,
          pubDate: post.pubDate,
          categories: post.categories
        };
        blogs.push(blog);
      }
      this.setState({ data: blogs });
    })();
  }

  categoryList = (id, categories) =>
    categories.map(category => (
      <div className="category" key={`${id}-${category}`}>
        {category}
      </div>
    ));

  render() {
    return (
      <div className="block">
        <h1 className="heading"> Blog Posts </h1>
        <br />
        <p>
          Here are my most recent blog posts. Check out my{" "}
          <a
            href="https://medium.com/@jordan.eckowitz"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black", textDecoration: "underline" }}
          >
            Medium 
          </a>
          {" "}page for a full list.
        </p>
        <br />
        {this.state.data ? (
          <div>
            {this.state.data.map(blog =>
              blog.categories ? (
                <h2 key={blog.id} className="card">
                  <a
                    href={blog.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "black" }}
                  >
                    {blog.title}
                    <div>{this.categoryList(blog.id, blog.categories)}</div>
                  </a>
                </h2>
              ) : null
            )}
          </div>
        ) : (
          <div style={{ marginBottom: "50px" }}>
            <PacmanLoader color={"#728ae5"} loading={true} />
          </div>
        )}
      </div>
    );
  }
}
