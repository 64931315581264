import React, { Component, Fragment } from "react";

// Prime React
import { Sidebar } from "primereact/sidebar";

// Reach Router
import { Link } from "@reach/router";

// Icons
import { IconContext } from "react-icons";
import { FaBars } from "react-icons/fa";

export default class NavBar extends Component {
  state = {
    visible: false,
    resume: false,
    blog: false,
    home: false,
    projects: false,
  };

  handleClick = event => {
    this.setState({
      resume: false,
      blog: false,
      projects: false,
    });
    this.setState({
      [event.target.name]: true
    });
  };

  handleSideBar = () => {
    this.setState({ visible: true });
  };

  navLinks = () => (
    <Fragment>
      <Link to="resume">
        <button
          className={`btn title ${this.state.resume}`}
          name="resume"
          onClick={this.handleClick}
        >
          RESUME
        </button>
      </Link>
      <Link to="projects">
        <button
          className={`btn title ${this.state.projects}`}
          name="projects"
          onClick={this.handleClick}
        >
          PROJECTS
        </button>
      </Link>
      <Link to="blog">
        <button
          className={`btn title ${this.state.blog}`}
          name="blog"
          onClick={this.handleClick}
        >
          BLOG
        </button>
      </Link>
    </Fragment>
    
  );

  render() {
    return (
      <div className="nav-bar">
        <Link to="/" style={{ color: "black" }}>
          <div className="title" name="home" onClick={this.handleClick}>
            Jordan Eckowitz
          </div>
        </Link>
        <div>
          <span className="nav-links">{this.navLinks()}</span>
          <button
            className="btn title menu-button"
            onClick={this.handleSideBar}
          >
            <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
              <FaBars />
            </IconContext.Provider>
          </button>
          <Sidebar
            visible={this.state.visible}
            position="right"
            style={{ width: "200px" }}
            dismissable={true}
            onHide={e => this.setState({ visible: false })}
          >
            {this.navLinks()}
          </Sidebar>
        </div>
      </div>
    );
  }
}
