import React from "react";

function Home() {
  return (
    <div className="block">
      <h1 className="heading">A bit about me</h1>
      <br />
      <p>
        I see myself as an inventor, I have always had a somewhat romantic idea
        of being able to turn scribbles from a piece of paper into something
        that adds value to the world - it's what drew me to becoming a
        Mechanical Engineer.
      </p>
      <br />
      <p>
        I realized a while ago that being an inventor in the 21st century
        requires keeping up with the times: trading in my paper and pen for a
        computer and keyboard. It is what inspired me to learn to code. I
        started off with Javascript and immediately fell in love. I have since
        broadened my horizons with technologies such as React, Redux, Ruby, Ruby
        on Rails, AWS, Firebase, Node.js, Meteor as well as both SQL and NoSQL
        databases.
      </p>
      <br />
      <p>
        If you'd like to get in touch email me at{" "}
        <a href="mailto:jordan.eckowitz@gmail.com" >
          jordan.eckowitz@gmail.com
        </a>
        .
      </p>
    </div>
  );
}

export default Home;
