import React from "react";

// Icons
import { IconContext } from "react-icons";
import { FaGithubSquare, FaLinkedin, FaMedium, FaEnvelope } from "react-icons/fa";

export default function About() {
  return (
    <h1 className="about title">
      Full Stack Developer
      <div>
        <IconContext.Provider value={{ className: "about-icon" }}>
          <a href="https://github.com/Jordan-Eckowitz" target="_blank" rel="noopener noreferrer" style={{color: "black"}}>
            <FaGithubSquare />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "about-icon" }}>
          <a href="https://www.linkedin.com/in/jordaneckowitz/" target="_blank" rel="noopener noreferrer" style={{color: "black"}}>
            <FaLinkedin />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "about-icon" }}>
          <a href="https://medium.com/@jordan.eckowitz" target="_blank" rel="noopener noreferrer" style={{color: "black"}}>
            <FaMedium />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "about-icon" }}>
          <a href="mailto:jordan.eckowitz@gmail.com" style={{color: "black"}}>
            <FaEnvelope />
          </a>
        </IconContext.Provider>
      </div>
    </h1>
  );
}
